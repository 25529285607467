import { Link } from "react-router-dom";

function Home(){
  
    return(
        <div className="Home_main">
            <div className="Home_text">
                <p className="Home_p1">Hallo, ich bin</p>
                <h1>Panagiotis <span className="colorword">Damianakis</span> </h1>
                <p className="Home_p2"><span className="Home_A">A</span> Web Developer</p>
                <div className="aPortfolio">
                    <Link to="/Portfolio">Portfolio</Link>
                </div>
                <div className="linksHome">
                    <div className="linksdivs">
                        <a href="https://cvdamianakis.web.app" target="_blank">CV</a>
                    </div>
                    <div className="linksdivs">
                        <a href="https://github.com/Panos15081985?tab=repositories" target="_blank">git</a>
                    </div>
                </div>
            </div>
            <div className="Home_foto w3-container w3-center w3-animate-zoom" >
                <img alt="foto" src="img/newfoto.jpg" ></img>
                
            </div>
            
        </div>
    )
}
export default Home;