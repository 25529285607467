
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Navigator from './Navigator'
import Aboutme from './Aboutme';
import Contact from './Contact';
import Portfolio from './Portfolio';


function App() {

  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Navigator/>}>
        <Route index element={<Home/>} />
        <Route path="Aboutme" element={<Aboutme/>} />
        <Route path="Contact" element={<Contact/>} />
        <Route path="Portfolio" element={<Portfolio/>} />
      </Route>
    </Routes>
  </BrowserRouter>
 
    
  )
}

export default App;
