import { Link, Outlet } from "react-router-dom";
import { useEffect,useState } from "react";
import { useLocation } from "react-router-dom";

function Navigator() {
  let [clickedLink,setClickedLink]=useState("")
  let location=useLocation()


  useEffect(()=>{
    setClickedLink(location.pathname)
  },[location])


  return (
    <div className="Nav_main">
      <div className="Nav_links w3-container w3-center w3-animate-right">
        <ul>
          <li>
            <Link
              id="home-link"
              to="/"
              style={{ color: clickedLink === "/" ? "rgb(0, 141, 141)" : "" }}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/Aboutme"
              style={{ color: clickedLink === "/Aboutme" ? "rgb(0, 141, 141)" : "" }}
            >
              Über
            </Link>
          </li>
          <li>
            <Link
              to="/Portfolio"
              style={{ color: clickedLink === "/Portfolio" ? "rgb(0, 141, 141)" : "" }}
            >
              Portfolio
            </Link>
          </li>
          <li>
            <Link
              to="/Contact"
              style={{ color: clickedLink === "/Contact" ? "rgb(0, 141, 141)" : "" }}
            >
              Kontakt
            </Link>
          </li>
        </ul>
      </div>
      <Outlet />
    </div>
  );
}

export default Navigator;