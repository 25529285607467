import Card from "./Card";
import Projects from "./Projects.json";

function Portfolio(){
  
    return(
        <div className="centerPortfolio w3-container w3-center w3-animate-top">
            <h1 className="titlePortfolio "><span style={{color:"rgb(0, 141, 141)"}}>Meine</span> Projekte</h1>
            <div className="portfolio">
                {Projects.map((Project,pidx)=>{
                    return(
                        <div key={pidx} >
                            <Card Project ={Project}/>
                        </div>)
                })}
            </div>
        </div>
    )
}
export default Portfolio;