
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDp2Np2cqS57sj4tO3NjmWwG7goN2hSiaQ",
  authDomain: "portofoliodamianakis.firebaseapp.com",
  projectId: "portofoliodamianakis",
  storageBucket: "portofoliodamianakis.appspot.com",
  messagingSenderId: "868477014968",
  appId: "1:868477014968:web:43129835d1f99af140f54d"
};


const app = initializeApp(firebaseConfig);
const firedb = getFirestore(app);
export default firedb ;