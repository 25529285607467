import firedb from '../src/database/Config';
import { collection ,addDoc} from "firebase/firestore";
import {useState} from 'react';

function Contact(){
    let[daten,setDaten]=useState({
        Name:"",
        mail:"",
        text:""
    });

    let SendEmail= async ()=>{
        if(daten.Name!=="" && daten.mail!=="" && daten.text!==""){
            try{
                const collectionRef = collection(firedb,"mail");
                const RefDocument =await addDoc(collectionRef, { 
                    to: ["damianakis.p@gmail.com"],
                    message:{
                        subject:"Name: "+daten.Name+" Email: "+daten.mail,
                        text:daten.text
                    }
                });
                setDaten({
                    Name:"",
                    mail:"",
                    text:""
                })
                alert("Danke für Ihre Nachricht")
            }
            catch(error){
                    console.log(error)
            }
        }
        else alert("Bitte alle Felder ausfüllen.")
        
    }

    return(
        <div className="contact">
            <div className="con_text  w3-animate-left">
                <p>Lass uns zusammenarbeiten<br/>Schreib mir eine Nachricht</p>
            </div>
            <div className="con_daten">
                <div className="con_dd">
                    <p className="con_p1">E-Mail Addresse :</p>
                    <p>damianakis.p@gmail.com</p>
                </div>
                <div className="con_dd">
                    <p className="con_p1">Telefonnummer :</p>
                    <p>+49 172 8182056</p>
                </div>
                <div className="con_dd">
                    <p className="con_p1">Meine Addresse :</p>
                    <p>Richard-Wagner-Str. 26, 50674 Cologne Germany</p>
                </div>
            </div>

            <div className="textSend">
                <h1>Eine E-Mail senden</h1>
                <input
                    placeholder="dein Name"
                    onChange={(e)=>{setDaten({...daten, Name:e.target.value})}}
                    value={daten.Name}
                />
                <input
                    placeholder="deine Email"
                    onChange={(e)=>{setDaten({...daten, mail:e.target.value})}}
                    value={daten.mail}
                />
                <textarea
                    placeholder="deine Nachricht"
                    onChange={(e)=>{setDaten({...daten, text:e.target.value})}}
                    value={daten.text}
                />
                <button onClick={SendEmail}>senden</button>
            </div>
        </div>
    )
}
export default Contact;