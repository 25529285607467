function Aboutme(){
    return(
        <div className="About_main">
            <div className="About_foto  w3-section">
                <div className="A_foto1">
                    <img className="w3-animate-top" alt="foto" src="img/map.jpg"></img>
                </div>
                <div className="A_foto2">
                    <img className="w3-animate-left" alt="foto" src="img/code.png"></img>  
                </div>
            </div>
            <div className="About_text">
                <div>
                    <h3>ÜBER MICH</h3>
                    <p>
                    Ich bin ein kreativer Entwickler aus Köln, Deutschland und begeistere mich für
                     die Erstellung beeindruckender Websites und Programme. Meine Expertise liegt darin,
                      Designs in voll funktionsfähige Websites zu erstellen und dabei meine 
                      Entwicklungskenntnisse einzusetzen, um Funktionen zu optimieren und anzupassen. 
                      Mein Ziel ist es, visuell ansprechende und benutzerfreundliche Websites und Programme 
                      zu gestalten, die ein nahtloses Browsing-Erlebnis bieten.
                    </p>
                </div>
                <h3>FÄHIGKEITEN</h3>
                <div className="skills w3-container w3-center w3-animate-right">
                    <div className="skillsImg">
                        <img src="img/react.png" alt="react JS"></img>
                        <img src="img/java.png" alt="JavaScript"></img>
                        <img src="img/html.png" alt="HTML"></img>
                        <img src="img/css.png" alt="CSS"></img>
                        <img src="img/xml.png" alt="XML"></img>
                        <img src="img/sql.png" alt="SQL"></img>
                        <img src="img/python.png" alt="Python"></img>
                        <img src="img/node.png" alt="NODE JS"></img>
                        <img src="img/php.png" alt="NODE JS"></img>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Aboutme;