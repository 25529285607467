function Card(props){
    let Project =props.Project
    console.log(Project.Daten)
    let UserDaten1= Project.Daten1;
    let UserDaten2= Project.Daten2;

    return(
        <div className="portfolio">
            <div className="Project" style={{backgroundImage:Project.backgroundfoto}} >
                <div className="Details" style={{backgroundImage:Project.backgroundlinear}}>
                    <div className="ProjektText">
                        <p>{Project.text}</p>
                    </div>
                    <div className="DetailsDatenLinks" style={{marginTop:Project.Links}}>
                        <div className="DetailsLinks">
                            <a  href={Project.hrefapp}  target="_blank"><img alt="foto" style={{borderRadius:Project.linkapp}} src={Project.srclink}></img></a>
                            <a  href={Project.hrefgit} target="_blank"><img alt="foto" src="img/gith.png"></img></a>
                        </div>
                        <div className="DetailsDaten" >
                            {UserDaten1!==undefined && <ul>
                                {UserDaten1.map((daten)=>{
                                    return (<li>{daten}</li>)
                                })}
                            </ul>}
                            {UserDaten2!==undefined && <ul>
                                {UserDaten2.map((daten)=>{
                                    return (<li>{daten}</li>)
                                })}
                            </ul>}
                        </div>
                    </div>
                </div>
                <div className="projectName" style={{backgroundColor:Project.backgroundName}}>
                    <h2 className="NameProject">{Project.NameProject}</h2>
                </div>
            </div>
        </div>

    )
}

export default Card;